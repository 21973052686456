import React from "react";
import {Translate} from "react-localize-redux";
import StorageHelper from "./StorageHelper";
import {Check} from "@material-ui/icons";
import {Button, WithStyles, withStyles} from "@material-ui/core";
import {initializeAnalytics} from "~components/Firebase";

const cookieStyles = {
	block: {
		backgroundColor: 'rgba(20,20,20,0.8)',
		minHeight: 26,
		bottom: 0,
		left: 0,
		right: 0,
		flexDirection: 'column',
		color: '#ccc',
		position: 'fixed',
		fontSize: '14px',
		padding: '8px 0 8px 30px',
		margin: 0,
		font: '14px/100% arial, sans-serif',
	},
}

interface CookieBannerState {
	cookiesAccepted: boolean;
}

class CookieBanner extends React.Component<WithStyles, CookieBannerState> {
	state = {
		cookiesAccepted: false,
	}

	constructor(props: any) {
		super(props);

		this.acceptCookies = this.acceptCookies.bind(this);
	}

	acceptCookies() {
		StorageHelper.setItem('cookiesAccepted', true);
		this.setState({cookiesAccepted: true});
		initializeAnalytics();
	}

	componentDidMount() {
		const cookiesAccepted = StorageHelper.getItem('cookiesAccepted', false);
		if (cookiesAccepted) {
			initializeAnalytics();
		}

		this.setState({cookiesAccepted: cookiesAccepted});
	}

	render() {
		return (
			<div style={{display: (this.state.cookiesAccepted ? 'none' : 'block')}} className={this.props.classes.block}>
				<Translate id="We use cookies to enhance your experience." />

				<a href="https://www.odania-it.com/data-protection" target="_blank" style={{marginLeft: 20}}>
					<Translate id="Data Protection"/>
				</a>

				<Button onClick={this.acceptCookies} variant="outlined" style={{marginLeft: 20}}>
					<Check /> <Translate id="Accept" />
				</Button>
			</div>
		)
	}
}

export default withStyles(cookieStyles)(CookieBanner)
