import * as React from "react";
import {db} from "~components/Firebase";
import {TParams} from "~Shared/Models";
import {RouteComponentProps, withRouter} from "react-router";
import {Translate} from "react-localize-redux";
import {getCategoryOrTagFor} from "~Shared/Utils";
import GridItem from "~components/Grid/GridItem";
import Card from "~components/Card/Card";
import CardHeader from "~components/Card/CardHeader";
import LocaleLink from "~components/Localization/LocaleLink";
import CardBody from "~components/Card/CardBody";
import Info from "~components/Typography/Info";
import GridContainer from "~components/Grid/GridContainer";
import {WithStyles, withStyles} from "@material-ui/core";
import sectionSimilarStoriesStyle
	from "~assets/jss/material-kit-pro-react/views/blogPostSections/sectionSimilarStoriesStyle";
import {Drink} from "~CustomApp/Models";

interface LatestDrinksProps {
	limit: number;
}

interface LatestDrinksState {
	drinks: firebase.firestore.QueryDocumentSnapshot[];
}

class LatestDrinks extends React.Component<LatestDrinksProps & RouteComponentProps<TParams> & WithStyles, LatestDrinksState> {
	state = {
		drinks: [] as firebase.firestore.QueryDocumentSnapshot[],
	}

	componentDidMount() {
		const locale = this.props.match.params.locale;

		if (!locale) {
			return;
		}

		db
			.collection(`contents/${locale}/drinks`)
			.where('visibility', '==', 'public')
			.orderBy('createdAt', 'desc')
			.limit(this.props.limit)
			.onSnapshot(snapshot => {
				this.setState({
					drinks: snapshot.docs,
				})
			})
	}

	render() {
		const {classes} = this.props

		return (
			<>
				<h2>
					<Translate id="Latest Drinks"/>
				</h2>

				<GridContainer>
					{this.state.drinks.map((doc, idx) => {
						const drink = doc.data() as Drink

						return (
							<GridItem xs={12} sm={4} md={4} key={idx}>
								<Card blog>
									<CardHeader image>
										<LocaleLink to={`drinks/${doc.id}`}>
											<img src={drink.imageUrl} alt={drink.title}/>
										</LocaleLink>
										<div
											className={classes.coloredShadow}
											style={{
												backgroundImage: "url(" + drink.imageUrl + ")",
												opacity: "1"
											}}
										/>
									</CardHeader>
									<CardBody>
										<Info>
											<h6>{getCategoryOrTagFor(drink)}</h6>
										</Info>
										<h4 className={classes.cardTitle}>
											<LocaleLink to={`drinks/${doc.id}`}>
												{drink.title}
											</LocaleLink>
										</h4>
										<p className={classes.description}>
											{drink.shortDescription}
											<LocaleLink to={`drinks/${doc.id}`}>
												<Translate id="Read More"/>
											</LocaleLink>
										</p>
									</CardBody>
								</Card>
							</GridItem>
						);
					})}
				</GridContainer>
			</>
		)
	}
}

export default withRouter(withStyles(sectionSimilarStoriesStyle)(LatestDrinks))
