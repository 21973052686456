import React from "react";
import {Content, Post, TParams} from "~Shared/Models";
import GridItem from "~components/Grid/GridItem";
import Card from "~components/Card/Card";
import CardBody from "~components/Card/CardBody";
import {getCategoryOrTagFor} from "~Shared/Utils";
import LocaleLink from "~components/Localization/LocaleLink";
import Button from "~components/CustomButtons/Button";
import FormatAlignLeft from "@material-ui/icons/FormatAlignLeft";
import {Translate} from "react-localize-redux";
import {withStyles, WithStyles} from "@material-ui/core/styles";
import sectionPillsStyle from "~assets/jss/material-kit-pro-react/views/blogPostsSections/sectionPillsStyle";
import {RouteComponentProps, withRouter} from "react-router-dom";

interface DisplayContentGridItemsProps {
	basePath: string;
	contents: Content[];
}

class DisplayContentGridItems extends React.Component<DisplayContentGridItemsProps & RouteComponentProps<TParams> & WithStyles, any> {
	goTo(slug: string) {
		this.props.history.push(`/${this.props.match.params.locale}/${this.props.basePath}/${slug}`)
	}

	render() {
		const {classes} = this.props;

		return (
			<>
				{this.props.contents.map((data, idx) => {
					const post = data as Post;

					return (
						<GridItem xs={12} sm={6} md={6} key={idx}>
							<Card
								raised
								background
								style={{backgroundImage: "url(" + post.imageUrl + ")"}}
							>
								<CardBody background>
									<h6 className={classes.category}>
										{getCategoryOrTagFor(post)}
									</h6>
									<LocaleLink to={`${this.props.basePath}/${post.slug}`}>
										<h3 className={classes.cardTitle}>
											{post.title}
										</h3>
									</LocaleLink>
									<p className={classes.category}>
										{post.body_short}
									</p>

									<Button round onClick={() => this.goTo(post.slug)} color="warning">
										<FormatAlignLeft className={classes.icons}/> <Translate id="Read Article"/>
									</Button>
								</CardBody>
							</Card>
						</GridItem>
					)
				})}
			</>
		)
	}
}

export default withRouter(withStyles(sectionPillsStyle)(DisplayContentGridItems))
