import React from "react";
import {createBrowserHistory} from "history";
import {Router} from "react-router-dom";
import BootstrapProvider from "~Shared/BootstrapProvider";
import {LocalizeProvider, withLocalize} from "react-localize-redux";
import App from "~components/App";

const hist = createBrowserHistory({basename: '/'});
class RouterApp extends React.Component<any, any> {
	render() {
		return (
			<LocalizeProvider>
				<Router history={hist}>
					<BootstrapProvider app="public">
						<App/>
					</BootstrapProvider>
				</Router>
			</LocalizeProvider>
		)
	}
}

export default withLocalize(RouterApp)
