/*eslint-disable*/
import React from "react";
// @material-ui/core components
import {WithStyles, withStyles} from "@material-ui/core/styles";
// @material-ui/icons
// core components
import GridContainer from "~components/Grid/GridContainer";
import GridItem from "~components/Grid/GridItem";
import Parallax from "~components/Parallax/Parallax";
// sections for this page
import {Translate} from "react-localize-redux";
import {retrieveDocumentFromFirestore} from "~components/Firebase";
import {Content} from "~Shared/Models";
import {RouteComponentProps, withRouter} from "react-router-dom";
import Button from "~components/CustomButtons/Button";
import FormatAlignLeft from "@material-ui/icons/FormatAlignLeft";
import SectionText from "~components/Content/SectionText";
import SectionBlogInfo from "./Sections/SectionBlogInfo";
import DisplayComments from "~components/Comments/DisplayComments";
import SectionSimilarStories from "./Sections/SectionSimilarStories";
import blogPostPageStyle from "~assets/jss/material-kit-pro-react/views/blogPostPageStyle";
import MetaTags from "~components/Utils/MetaTags";
import {handleImage} from "~Shared/Utils";
import Loading from "~components/Loading/Loading";
import NotFound from "~components/ErrorPage/NotFound";
import classNames from "classnames";

export interface DisplayContentPageProps {
	collectionPath: string;
}

interface DisplayContentPageState {
	content: Content | undefined;
	documentFound: boolean;
	isLoading: boolean;
}

type TParams = {
	id: string;
	locale: string;
}

class DisplayContentPage extends React.Component<DisplayContentPageProps & WithStyles & RouteComponentProps<TParams>, DisplayContentPageState> {
	state = {
		content: {} as Content,
		documentFound: true,
		isLoading: true,
	}

	componentDidMount() {
		window.scrollTo(0, 0);
		document.body.scrollTop = 0;

		const docId = this.props.match.params.id;
		this.loadData(docId);
	}

	loadData(docId: string) {
		const locale = this.props.match.params.locale;
		let collectionPath = `contents/${locale}/${this.props.collectionPath}`;
		retrieveDocumentFromFirestore(collectionPath, docId, doc => {
			if (!doc.exists) {
				console.error(`Document does not exist! ${collectionPath}/${docId}`);
				this.setState({documentFound: false, isLoading: false})
				return;
			}

			console.log('DisplayContentPage', doc.data())
			this.setState({content: doc.data() as Content, documentFound: true, isLoading: false})
			handleImage(this, this.state.content)
		}, err => {
			this.setState({documentFound: false, isLoading: false})
		})
	}

	componentDidUpdate(prevProps: Readonly<DisplayContentPageProps & WithStyles & RouteComponentProps<TParams>>, prevState: Readonly<DisplayContentPageState>, snapshot?: any) {
		const docId = this.props.match.params.id;
		if (prevProps.collectionPath !== this.props.collectionPath || prevProps.match.params.id !== docId) {
			this.loadData(docId);
		}
	}

	render() {
		const {classes} = this.props;
		const {content} = this.state;
		const ref = React.createRef<HTMLDivElement>();

		if (this.state.isLoading) {
			return <Loading/>
		}

		if (!this.state.documentFound) {
			return <NotFound/>
		}

		return (
			<div>
				<MetaTags title={content.title}/>
				<Parallax image={content.imageUrl} filter="dark" className={classes.parallax}>
					<div className={classes.container}>
						<GridContainer justify="center">
							<GridItem md={8} className={classes.textCenter}>
								<h1 className={classes.title}>
									{content.title}
								</h1>
								<h4 className={classes.subtitle}>
									{content.subtitle}
								</h4>
							</GridItem>
						</GridContainer>
					</div>
				</Parallax>
				<div className={classNames(classes.main, classes.mainRaised)}>
					<div className={classes.container} ref={ref}>
						<SectionText body={content.body}/>
						<SectionBlogInfo content={content}/>
						<DisplayComments comments={[]}/>
					</div>
				</div>
				<SectionSimilarStories/>
			</div>
		);
	}
}

export default withRouter(withStyles(blogPostPageStyle)(DisplayContentPage))
