/*eslint-disable*/
import React from "react";
import classNames from "classnames";
import {withStyles, WithStyles} from "@material-ui/core/styles";
import GridContainer from "~components/Grid/GridContainer";
import GridItem from "~components/Grid/GridItem";
import Parallax from "~components/Parallax/Parallax";
import Clearfix from "~components/Clearfix/Clearfix";

import profilePageStyle from "~assets/jss/material-kit-pro-react/views/profilePageStyle";
import {BootstrapContextProps} from "~Shared/BootstrapContext";
import {withBootstrap} from "~Shared/withBootstrap";
import {TParams, User} from "~Shared/Models";
import {Redirect, RouteComponentProps, withRouter} from "react-router-dom";

interface ProfilePageState {
	user: User;
}

class ProfilePage extends React.Component<BootstrapContextProps & RouteComponentProps<TParams> & WithStyles, ProfilePageState> {
	state = {
		user: {} as User,
	}

	componentDidMount() {
		window.scrollTo(0, 0);
		document.body.scrollTop = 0;
	}

	loadUserData() {

	}

	render() {
		const {classes, currentUser} = this.props;
		const {user} = this.state;
		const imageClasses = classNames(
			classes.imgRaised,
			classes.imgRoundedCircle,
			classes.imgFluid
		);

		if (!currentUser) {
			return <Redirect to={`/${this.props.match.params.locale}/login`}/>
		}

		return (
			<div>
				<Parallax
					image={require("~assets/img/examples/city.jpg")}
					filter="dark"
					className={classes.parallax}
				/>
				<div className={classNames(classes.main, classes.mainRaised)}>
					<div className={classes.container}>
						<GridContainer justify="center">
							<GridItem xs={12} sm={12} md={6}>
								<div className={classes.profile}>
									{currentUser?.photoURL &&
									<div>
										<img src={currentUser.photoURL} alt="..." className={imageClasses}/>
									</div>
									}
									<div className={classes.name}>
										<h3 className={classes.title}>
											{currentUser?.displayName}
										</h3>
										<h6>
											{user.tags}
										</h6>
									</div>
								</div>
							</GridItem>
						</GridContainer>
						<div className={classNames(classes.description, classes.textCenter)}>
							<p>
								{user.description}
							</p>
						</div>
						<Clearfix/>
					</div>
				</div>
			</div>
		);
	}
}

export default withRouter(withBootstrap(withStyles(profilePageStyle)(ProfilePage)))
