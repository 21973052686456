import React from "react";
import {Link} from "react-router-dom";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import {WithStyles, withStyles} from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import Hidden from "@material-ui/core/Hidden";
import Drawer from "@material-ui/core/Drawer";
// @material-ui/icons
import Menu from "@material-ui/icons/Menu";
import Close from "@material-ui/icons/Close";
// core components
import headerStyle from "~assets/jss/material-kit-pro-react/components/headerStyle";
import {BootstrapContextProps} from "~Shared/BootstrapContext";
import {withBootstrap} from "~Shared/withBootstrap";
import HeaderLinks from "~components/Header/HeaderLinks";

interface ChangeColorOnScrollInterface {
	height: number;
	color: "primary" | "info" | "success" | "warning" | "danger" | "transparent" | "white" | "rose" | "dark";
}

interface HeaderProps {
	color: "primary" | "info" | "success" | "warning" | "danger" | "transparent" | "white" | "rose" | "dark";
	links: any; // PropTypes.node
	fixed: boolean;
	absolute: boolean;
	// this will cause the sidebar to change the color from
	// props.color (see above) to changeColorOnScroll.color
	// when the window.pageYOffset is heigher or equal to
	// changeColorOnScroll.height and then when it is smaller than
	// changeColorOnScroll.height change it back to
	// props.color (see above)
	changeColorOnScroll: ChangeColorOnScrollInterface;
}

interface HeaderState {
	mobileOpen: boolean;
}

class Header extends React.Component<BootstrapContextProps & HeaderProps & WithStyles, HeaderState> {
	public static defaultProps = {
		color: "white"
	}

	state = {
		mobileOpen: false,
	}

	constructor(props: any) {
		super(props);

		this.headerColorChange = this.headerColorChange.bind(this)
	}

	headerColorChange() {
		const {classes} = this.props;
		const {color, changeColorOnScroll} = this.props;

		const windowsScrollTop = window.pageYOffset;
		if (windowsScrollTop > changeColorOnScroll.height) {
			document.body
				.getElementsByTagName("header")[0]
				.classList.remove(classes[color]);
			document.body
				.getElementsByTagName("header")[0]
				.classList.add(classes[changeColorOnScroll.color]);
		} else {
			document.body
				.getElementsByTagName("header")[0]
				.classList.add(classes[color]);
			document.body
				.getElementsByTagName("header")[0]
				.classList.remove(classes[changeColorOnScroll.color]);
		}
	}

	componentDidMount(): void {
		if (this.props.changeColorOnScroll) {
			window.addEventListener("scroll", this.headerColorChange);
		}
	}

	componentWillUnmount(): void {
		if (this.props.changeColorOnScroll) {
			window.removeEventListener("scroll", this.headerColorChange);
		}
	}

	render() {
		const handleDrawerToggle = () => {
			this.setState({mobileOpen: !this.state.mobileOpen})
		};
		const {classes, color, header, fixed, absolute} = this.props;
		const appBarClasses = classNames({
			[classes.appBar]: true,
			[classes[color]]: color,
			[classes.absolute]: absolute,
			[classes.fixed]: fixed
		});

		const links = <HeaderLinks dropdownHoverColor="info"/>

		return (
			<AppBar className={appBarClasses}>
				<Toolbar className={classes.container}>
					<Button className={classes.title}>
						<Link to="/">{header.brand}</Link>
					</Button>
					<Hidden smDown implementation="css" className={classes.hidden}>
						<div className={classes.collapse}>{links}</div>
					</Hidden>
					<Hidden mdUp>
						<IconButton
							color="inherit"
							aria-label="open drawer"
							onClick={handleDrawerToggle}
						>
							<Menu/>
						</IconButton>
					</Hidden>
				</Toolbar>
				<Hidden mdUp implementation="js">
					<Drawer
						variant="temporary"
						anchor={"right"}
						open={this.state.mobileOpen}
						classes={{
							paper: classes.drawerPaper
						}}
						onClose={handleDrawerToggle}
					>
						<IconButton
							color="inherit"
							aria-label="open drawer"
							onClick={handleDrawerToggle}
							className={classes.closeButtonDrawer}
						>
							<Close/>
						</IconButton>
						<div className={classes.appResponsive}>{links}</div>
					</Drawer>
				</Hidden>
			</AppBar>
		);
	}
}

export default withBootstrap(withStyles(headerStyle)(Header))
