import {withBootstrap} from "~Shared/withBootstrap";
import {BootstrapContextProps} from "~Shared/BootstrapContext";
import {Link} from "react-router-dom";
import Category from "@material-ui/icons/Category";
import CustomDropdown from "~components/CustomDropdown/CustomDropdown";
import React from "react";
import {withStyles, WithStyles} from "@material-ui/core/styles";

import styles from "~assets/jss/material-kit-pro-react/components/headerLinksStyle";
import {HeaderLinksInterfaceProps} from "~components/Header/HeaderLinks";
import {Translate} from "react-localize-redux";
import Icon from '@material-ui/core/Icon';
import LocaleLink from "~components/Localization/LocaleLink";

class HeaderCategories extends React.Component<BootstrapContextProps & WithStyles & HeaderLinksInterfaceProps, any> {
	render() {
		const {classes, dropdownHoverColor} = this.props;

		const categoryLinks = []
		for (const category of this.props.categories) {
			categoryLinks.push(
				<LocaleLink to={`categories/${category.slug}`} className={classes.dropdownLink}>
					<Icon className={classes.dropdownIcons} key={category.icon}/> {category.title}
				</LocaleLink>
			)
		}

		if (this.props.categories.length > -1) {
			return null
		}

		return (
			<CustomDropdown
				noLiPadding
				navDropdown
				hoverColor={dropdownHoverColor}
				buttonText={<Translate id="Categories"/>}
				buttonProps={{
					className: classes.navLink,
					color: "transparent"
				}}
				buttonIcon={Category}
				dropdownList={categoryLinks}
			/>
		)
	}
}

export default withBootstrap(withStyles(styles)(HeaderCategories))
