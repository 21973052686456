/*eslint-disable*/
import React from "react";
// @material-ui/core components
import {WithStyles, withStyles} from "@material-ui/core/styles";
// @material-ui/icons
// core components
import GridContainer from "~components/Grid/GridContainer";
import GridItem from "~components/Grid/GridItem";
import Parallax from "~components/Parallax/Parallax";
// sections for this page
import SectionPills from "./Sections/SectionPills";
import SectionInterested from "./Sections/SectionInterested";

import blogPostsPageStyle from "~assets/jss/material-kit-pro-react/views/blogPostsPageStyle";
import {titleImage} from "~config";
import {LocalizeContextProps, withLocalize} from "react-localize-redux";
import {db, queryForDocuments} from "~components/Firebase";
import {Content, TParams} from "~Shared/Models";
import Loading from "~components/Loading/Loading";
import {RouteComponentProps, withRouter} from "react-router-dom";
import {handleImageList, scrollTo} from "~Shared/Utils";
import MetaTags from "~components/Utils/MetaTags";
import classNames from "classnames";

export interface ContentListPageProps {
	collectionPath: string;
	limit: number;
	title: string;
}

interface ContentListPageState {
	contents: Content[];
	isLoading: boolean;
	firstElement: any;
	lastElement: any;
}

class ContentListPage extends React.Component<ContentListPageProps & LocalizeContextProps & WithStyles & RouteComponentProps<TParams>, ContentListPageState> {
	public static defaultProps = {
		limit: 20,
	}

	state = {
		contents: [],
		isLoading: true,
		firstElement: null,
		lastElement: null,
	}

	constructor(props: any) {
		super(props);

		this.loadNext = this.loadNext.bind(this);
		this.loadPrevious = this.loadPrevious.bind(this);
	}

	componentDidMount() {
		window.scrollTo(0, 0);
		document.body.scrollTop = 0;

		this.loadData();
	}

	loadData(startAfter = null, endBefore = null) {
		const locale = this.props.match.params.locale;

		if (!locale) {
			console.warn('No locale');
			return;
		}

		let collectionPath = `contents/${locale}/${this.props.collectionPath}`;
		console.log(this.props.collectionPath, startAfter, endBefore)

		let query = db
			.collection(collectionPath)
			.where('visibility', '==', 'public')
			.orderBy('createdAt', 'desc')
			.limit(this.props.limit)

		if (startAfter !== null) {
			query = query.startAfter(startAfter)
		} else if (endBefore !== null) {
			query = query.endBefore(endBefore)
		}

		queryForDocuments(query, result => {
			console.log('+++++++++++++++++++++++++++++++++++++', result.documents)
			this.setState({
				contents: result.documents as Content[],
				isLoading: false,
				firstElement: result.documents[0].createdAt,
				lastElement: result.documents[result.documents.length - 1].createdAt,
			})

			for (let idx = 0 ; idx < this.state.contents.length ; idx++) {
				const content = this.state.contents[idx]
				handleImageList(this, content, idx);
			}

		}, err => {
			console.error('ERROR', err)
		})
	}

	loadNext(ref: React.RefObject<HTMLDivElement>) {
		this.loadData(this.state.lastElement);
		scrollTo(ref);
	}

	loadPrevious(ref: React.RefObject<HTMLDivElement>) {
		this.loadData(null, this.state.firstElement);
		scrollTo(ref);
	}

	render() {
		const {classes, translate} = this.props;
		const ref = React.createRef<HTMLDivElement>()
		const title = translate(this.props.title)

		if (this.state.isLoading) {
			return <Loading image={titleImage}/>
		}

		return (
			<div>
				<MetaTags title={title}/>
				<Parallax image={titleImage} filter="dark" className={classes.parallax}>
					<div className={classes.container}>
						<GridContainer justify="center">
							<GridItem xs={12} sm={12} md={8} className={classes.textCenter}>
								<h2 className={classes.title}>
									{title}
								</h2>
							</GridItem>
						</GridContainer>
					</div>
				</Parallax>
				<div className={classNames(classes.main, classes.mainRaised)}>
					<div className={classes.container} ref={ref}>
						<SectionPills basePath={this.props.collectionPath}
													contents={this.state.contents}
													loadNext={() => this.loadNext(ref)}
													loadPrevious={() => this.loadPrevious(ref)}/>
						<SectionInterested/>
					</div>
				</div>
			</div>
		);

		// <SectionImage/>
		// <SubscribeLine/>
	}
}

export default withLocalize(withRouter(withStyles(blogPostsPageStyle)(ContentListPage)))
