import React from "react";
import {withStyles, WithStyles} from "@material-ui/core/styles";
import Parallax from "~components/Parallax/Parallax";
import GridContainer from "~components/Grid/GridContainer";
import GridItem from "~components/Grid/GridItem";

import blogPostPageStyle from "~assets/jss/material-kit-pro-react/views/blogPostPageStyle";
import {Category, TParams} from "~Shared/Models";
import MetaTags from "~components/Utils/MetaTags";
import {LocalizeContextProps, withLocalize} from "react-localize-redux";
import classNames from "classnames";
import {db} from "~components/Firebase";
import {RouteComponentProps, withRouter} from "react-router-dom";
import LocaleLink from "~components/Localization/LocaleLink";
import Icon from "@material-ui/core/Icon";
import CustomAppCategoriesAddition from "~CustomApp/Components/Categories/CustomAppCategoriesAddition";

interface CategoriesPageState {
	categories: firebase.firestore.QueryDocumentSnapshot[];
}

class CategoriesPage extends React.Component<LocalizeContextProps & RouteComponentProps<TParams> & WithStyles, CategoriesPageState> {
	state = {
		categories: [],
	}

	componentDidMount() {
		window.scrollTo(0, 0);
		document.body.scrollTop = 0;

		this.loadCategories()
	}

	loadCategories() {
		const locale = this.props.match.params.locale;
		let collectionPath = `contents/${locale}/categories`;
		db
			.collection(collectionPath)
			.where('visibility', '==', 'public')
			.orderBy('title', 'asc')
			.onSnapshot(snapshot => {
				console.log('loadCategories', snapshot.docs, snapshot.empty)
				this.setState({categories: snapshot.docs})
			})
	}

	render() {
		const {classes, translate} = this.props;
		const title = translate('Categories')

		return (
			<div>
				<MetaTags title={title}/>
				<Parallax image={require("~assets/img/bg5.jpg")} filter="dark" className={classes.parallax}>
					<div className={classes.container}>
						<GridContainer justify="center">
							<GridItem md={8} className={classes.textCenter}>
								<h1 className={classes.title}>
									{title}
								</h1>
							</GridItem>
						</GridContainer>
					</div>
				</Parallax>
				<div className={classNames(classes.main, classes.mainRaised)}>
					<div className={classes.container}>
						{this.state.categories.map((doc, idx) => {
							const category = doc.data() as Category

							return (
								<div key={idx}>
									<LocaleLink to={`categories/${category.slug}`}>
										<Icon className={classes.dropdownIcons} key={category.icon}/> {category.title}
									</LocaleLink>
								</div>
							)
						})}

						<CustomAppCategoriesAddition/>
					</div>
				</div>
			</div>
		);
	}
}

export default withRouter(withLocalize(withStyles(blogPostPageStyle)(CategoriesPage)))
