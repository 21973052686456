import React from "react";
import {withBootstrap} from "~Shared/withBootstrap";
import {Redirect, RouteComponentProps, withRouter} from "react-router-dom";
import {BootstrapContextProps} from "~Shared/BootstrapContext";
import {TParams} from "~Shared/Models";

interface PrivateRouteProps {
	component: any;
}

class PrivateRoute extends React.Component<BootstrapContextProps & PrivateRouteProps & RouteComponentProps<TParams>, any> {
	render() {
		if (!!this.props.currentUser) {
			return this.props.component
		}

		const parts = this.props.location.pathname.split('/')
		return <Redirect to={`/${parts[1]}/login`}/>
	}
}

export default withBootstrap(withRouter(PrivateRoute))
