import React from "react";
import {withBootstrap} from "~Shared/withBootstrap";
import {BootstrapContextProps} from "~Shared/BootstrapContext";
import {titleImage} from "~config";
import GridContainer from "~components/Grid/GridContainer";
import GridItem from "~components/Grid/GridItem";
import Parallax from "~components/Parallax/Parallax";
import {withStyles} from "@material-ui/core";
import blogPostPageStyle from "~assets/jss/material-kit-pro-react/views/blogPostPageStyle";
import {WithStyles} from "@material-ui/core/styles";
import MetaTags from "~components/Utils/MetaTags";
import LatestContent from "~components/WIdgets/LatestContent";
import {Translate} from "react-localize-redux";
import classNames from "classnames";

class Home extends React.Component<BootstrapContextProps & WithStyles, any> {
	render() {
		const {classes} = this.props;

		return (
			<div>
				<MetaTags title={this.props.home.title}/>
				<Parallax image={titleImage} filter="dark" small>
					<div className={classes.container}>
						<GridContainer justify="center">
							<GridItem xs={12} sm={12} md={8} className={classes.textCenter}>
								<h2 className={classes.title}>
									{this.props.home.title}
								</h2>
							</GridItem>
						</GridContainer>
					</div>
				</Parallax>

				<div className={classNames(classes.main, classes.mainRaised)}>
					<div className={classes.container}>
						<p dangerouslySetInnerHTML={{__html: this.props.home.body}}/>

						{this.props.home.displayLatestPosts &&
						<LatestContent collectionPath="posts" title={<Translate id="Posts"/>} limit={3}/>
						}
					</div>
				</div>
			</div>
		)
	}
}

export default withBootstrap(withStyles(blogPostPageStyle)(Home))
