import React from "react";
import {Route} from "react-router-dom";
import DrinkList from "~CustomApp/Views/Drinks/DrinkList";
import ShowDrinkPage from "~CustomApp/Views/Drinks/ShowDrinkPage";

class CustomAppRoutes extends React.Component<any, any> {
	render() {
		return (
			<>
				<Route path="/:locale/drinks/:id" exact={true} component={ShowDrinkPage}/>
				<Route path="/:locale/drinks" exact={true} component={DrinkList}/>
			</>
		)
	}
}

export default CustomAppRoutes
