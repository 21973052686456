import React from "react";
import MetaTags from "~components/Utils/MetaTags";
import Parallax from "~components/Parallax/Parallax";
import {titleImage} from "~config";
import GridContainer from "~components/Grid/GridContainer";
import GridItem from "~components/Grid/GridItem";
import {Translate} from "react-localize-redux";
import {withStyles} from "@material-ui/core";
import blogPostPageStyle from "~assets/jss/material-kit-pro-react/views/blogPostPageStyle";
import {WithStyles} from "@material-ui/core/styles";
import {withBootstrap} from "~Shared/withBootstrap";
import {BootstrapContextProps} from "~Shared/BootstrapContext";
import ProtectedHomeAddition from "~CustomApp/Components/Protected/ProtectedHomeAddition";
import classNames from "classnames";

class ProtectedHome extends React.Component<BootstrapContextProps & WithStyles, any> {
	render() {
		const {classes} = this.props;

		return (
			<div>
				<MetaTags title="Willkommen"/>
				<Parallax image={titleImage} filter="dark" small className={classes.parallax}>
					<div className={classes.container}>
						<GridContainer justify="center">
							<GridItem xs={12} sm={12} md={8} className={classes.textCenter}>
								<h2 className={classes.title}>
									<Translate id="Welcome"/> {this.props.currentUser?.displayName}!
								</h2>
							</GridItem>
						</GridContainer>
					</div>
				</Parallax>

				<div className={classNames(classes.main, classes.mainRaised)}>
					<div className={classes.container}>
						<h2>
							<Translate id="Welcome"/> {this.props.currentUser?.displayName}!
						</h2>

						<ProtectedHomeAddition/>
					</div>
				</div>
			</div>
		)
	}
}

export default withBootstrap(withStyles(blogPostPageStyle)(ProtectedHome))
