/*eslint-disable*/
import React from "react";
// nodejs library to set properties for components
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import {makeStyles} from "@material-ui/core/styles";
// @material-ui/icons
// core components
import Header from "~components/Header/Header";
import Footer from "~components/Footer/Footer";
import GridContainer from "~components/Grid/GridContainer";
import GridItem from "~components/Grid/GridItem";
import Button from "~components/CustomButtons/Button";
import HeaderLinks from "~components/Header/HeaderLinks";
import Parallax from "~components/Parallax/Parallax";

import landingPageStyle from "~assets/jss/material-kit-pro-react/views/landingPageStyle";
// Sections for this page
import SectionProduct from "./Sections/SectionProduct";
import SectionTeam from "./Sections/SectionTeam";
import SectionWork from "./Sections/SectionWork";

const useStyles = makeStyles(landingPageStyle);

export default function LandingPage({...rest}) {
	React.useEffect(() => {
		window.scrollTo(0, 0);
		document.body.scrollTop = 0;
	});
	const classes = useStyles();
	return (
		<div>
			<Header
				color="transparent"
				links={<HeaderLinks dropdownHoverColor="info"/>}
				fixed
				changeColorOnScroll={{
					height: 300,
					color: "info"
				}}
				{...rest}
			/>
			<Parallax image={require("~assets/img/bg8.jpg")} filter="dark" className={classes.parallax}>
				<div className={classes.container}>
					<GridContainer>
						<GridItem xs={12} sm={6} md={6}>
							<h1 className={classes.title}>Your Story Starts With Us.</h1>
							<h4>
								Every landing page needs a small description after the big bold
								title, that{"'"}s why we added this text here. Add here all the
								information that can make you or your product create the first
								impression.
							</h4>
							<br/>
							<Button
								color="danger"
								size="lg"
								href="https://www.youtube.com/watch?v=dQw4w9WgXcQ&ref=creativetim"
								target="_blank"
							>
								<i className="fas fa-play"/>
								Watch video
							</Button>
						</GridItem>
					</GridContainer>
				</div>
			</Parallax>
			<div className={classNames(classes.main, classes.mainRaised)}>
				<div className={classes.container}>
					<SectionProduct/>
					<SectionTeam/>
					<SectionWork/>
				</div>
			</div>
			<Footer/>
		</div>
	);
}
