import React from "react";
import ListItem from "@material-ui/core/ListItem";
import styles from "~assets/jss/material-kit-pro-react/components/headerLinksStyle";
import {WithStyles, withStyles} from "@material-ui/core/styles";
import {Translate} from "react-localize-redux";
import {LocalDrink} from "@material-ui/icons";
import {HeaderLinksInterfaceProps} from "~components/Header/HeaderLinks";
import LocaleLink from "~components/Localization/LocaleLink";
import {BootstrapContextProps} from "~Shared/BootstrapContext";
import {withBootstrap} from "~Shared/withBootstrap";

class CustomAppHeaderLinks extends React.Component<BootstrapContextProps & HeaderLinksInterfaceProps & WithStyles, any> {
	render() {
		const {classes} = this.props

		return (
			<>
				<ListItem className={classes.listItem}>
					<LocaleLink to={`drinks`} className={classes.navLink}>
						<LocalDrink/> <Translate id="Drinks"/>
					</LocaleLink>
				</ListItem>
			</>
		)
	}
}

export default withBootstrap(withStyles(styles)(CustomAppHeaderLinks))
