import {renderToStaticMarkup} from "react-dom/server";
import { availableLanguages } from "~config";

class LocalizeHelper {
	public static localeConfig() {
		return {
			languages: availableLanguages,
			options: {renderToStaticMarkup, renderInnerHtml: true}
		};
	}
}

export default LocalizeHelper
