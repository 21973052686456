import React from "react";

export const translations = {
	'About us': 'Über uns',
	'Add': 'Hinzufügen',
	'Accept': 'Akzeptieren',
	'Categories': 'Kategorien',
	'Category': 'Kategorie',
	'Contact us': 'Kontakt',
	'Contact form': 'Kontakformular',
	'Creator': 'Erstellt von',
	'Data Protection': 'Datenschutz',
	'Description': 'Beschreibung',
	'Email address': 'Email Adresse',
	'German': 'Deutsch',
	'Group Info': 'Gruppen Info',
	'Imprint': 'Impressum',
	'Language': 'Sprache',
	'Latests Posts': 'Neueste Beiträge',
	'Loading...': 'Loading...',
	'Logout': 'Abmelden',
	'Member': 'Mitglieder',
	'Members': 'Mitglieder',
	'Messages': 'Nachrichten',
	'Next': 'Nächste',
	'Pages': 'Seiten',
	'Phone': 'Telefon',
	'Please wait': 'Bitte warten',
	'Post your comment': 'Schreibe einen Kommentar',
	'Posts': 'Beiträge',
	'Previous': 'Vorige',
	'Profile': 'Profil',
	'Read Article': 'Artikel lesen',
	'Read More': 'Weiterlesen',
	'Save': 'Speichern',
	'Send us a message': 'Sende uns eine Nachricht',
	'Settings': 'Einstellungen',
	'Show': 'Zeigen',
	'Subtitle': 'Subtitle',
	'Success Journal': 'Erfolgsjournal',
	'Submit': 'Senden',
	'Tags': 'Tags',
	'Title': 'Titel',
	'Visibility': 'Sichtbarkeit',
	'We use cookies to enhance your experience.': 'Wir benutzen Cookies um deinen Besuch optimal zu gestalten.',
	'Wishes and goals': 'Wünsche und Ziele',
	'You can contact us if you have any questions or suggestions. All Feedback Welcome.':
		'Du kannst uns gerne kontaktieren. Fragen, Vorschläge und Feedback ist Willkommen.',
	'Your message': 'Deine Nachricht',
	'Your Name': 'Dein Name',
};
