import React from "react";
import {WithStyles, withStyles} from "@material-ui/core/styles";
import GridContainer from "~components/Grid/GridContainer";
import GridItem from "~components/Grid/GridItem";
import NavPills from "~components/NavPills/NavPills";
import Button from "~components/CustomButtons/Button";

import sectionPillsStyle from "~assets/jss/material-kit-pro-react/views/blogPostsSections/sectionPillsStyle";
import {Content, TParams} from "~Shared/Models";
import {Translate} from "react-localize-redux";
import {withRouter} from "react-router-dom";
import {RouteComponentProps} from "react-router";
import DisplayContentGridItems from "~components/Content/DisplayContentGridItems";

interface SectionPillsProps {
	basePath: string;
	loadNext: () => void;
	loadPrevious: () => void;
	contents: Content[];
}

class SectionPills extends React.Component<SectionPillsProps & WithStyles & RouteComponentProps<TParams>, any> {
	render() {
		const {classes, contents} = this.props;
		const navPills = [];

		return (
			<div className={classes.section}>
				{navPills.length !== 0 &&
				<GridContainer justify="center">
					<GridItem xs={12} sm={12} md={8} className={classes.textCenter}>
						<NavPills
							alignCenter
							tabs={[
								{
									tabButton: "All",
									tabContent: ""
								},
								{
									tabButton: "World",
									tabContent: ""
								},
								{
									tabButton: "Arts",
									tabContent: ""
								},
								{
									tabButton: "Tech",
									tabContent: ""
								},
								{
									tabButton: "Business",
									tabContent: ""
								}
							]}
						/>
						<div className={classes.tabSpace}/>
					</GridItem>
				</GridContainer>
				}
				<GridContainer>
					<DisplayContentGridItems basePath={this.props.basePath} contents={contents}/>
				</GridContainer>
				<GridContainer>
					<Button onClick={this.props.loadPrevious} className={classes.buttons}>
						<Translate id="Previous"/>
					</Button>
					<Button onClick={this.props.loadNext} className={classes.buttons}>
						<Translate id="Next"/>
					</Button>
				</GridContainer>
			</div>
		);
	}
}

export default withRouter(withStyles(sectionPillsStyle)(SectionPills))
