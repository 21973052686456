export const firebaseConfig = {
	apiKey: "AIzaSyDCW7QURBPrKvAFqQMrmDydUWbEtx77JPQ",
	authDomain: "tasting-planner-app-production.firebaseapp.com",
	databaseURL: "https://tasting-planner-app-production.firebaseio.com",
	projectId: "tasting-planner-app-production",
	storageBucket: "tasting-planner-app-production.appspot.com",
	messagingSenderId: "717612728685",
	appId: "1:717612728685:web:826b36ae38496ae4e35a64",
	measurementId: "G-SPPL3Z694KV"
};

export const metaData = {
	title: 'Tasting-Planner.com',
	description: 'Tasting-Planner.com',
};

export const titleImage = require("~assets/img/pixabay/paper-3213924_1920.jpg");
export const allowedRobotHost = 'www.tasting-planner.com';

export const availableLanguages = [
	
	{name: "German", code: "de"},
	
	{name: "English", code: "en"},
	
];

export const categoryAssets = {
	
	Default: "public/categories/paper-3213924_1920.jpg",
	
};
