import React from "react";
import {Redirect} from "react-router-dom";

class RedirectToLocale extends React.Component<any, any> {
	render() {
		return <Redirect to={'/de'} />
	}
}

export default RedirectToLocale
