import image from "~assets/img/clint-mckoy.jpg";
import GridContainer from "~components/Grid/GridContainer";
import GridItem from "~components/Grid/GridItem";
import React from "react";
import {WithStyles, withStyles} from "@material-ui/core";
import errorPageStyle from "~assets/jss/material-kit-pro-react/views/errorPageStyles";

class NotFound extends React.Component<WithStyles, any> {
	render() {
		const {classes} = this.props;

		return (
			<div
				className={classes.pageHeader}
				style={{
					backgroundImage: "url(" + image + ")",
					backgroundSize: "cover",
					backgroundPosition: "top center"
				}}
			>
				{/* <div className={classes.container}> */}
				<div className={classes.contentCenter}>
					<GridContainer>
						<GridItem md={12}>
							<h1 className={classes.title}>404</h1>
							<h2 className={classes.subTitle}>Page not found :(</h2>
							<h4 className={classes.description}>
								Ooooups! Looks like you got lost.
							</h4>
						</GridItem>
					</GridContainer>
				</div>
				{/* </div> */}
			</div>
		)
	}
}

export default withStyles(errorPageStyle)(NotFound)
