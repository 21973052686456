import * as React from "react";
import {Context} from "react";
import {Category, Dictionary, StaticPage} from "./Models";
import firebase from "firebase/app";

export type BootstrapContextFooter = {
	links: Dictionary;
}

export type BootstrapContextHeader = {
	brand: string;
	staticPages: StaticPage[];
}

export type BootstrapContextHome = {
	body: string;
	displayLatestPosts: boolean;
	title: string;
}

export type BootstrapContextProps = {
	bootstrapFinished: boolean;
	categories: Category[];
	changeLocale: (locale: any) => void;
	currentLocale: string;
	currentUser: firebase.User | null;
	footer: BootstrapContextFooter;
	header: BootstrapContextHeader;
	home: BootstrapContextHome;
};

export const BootstrapContext: Context<BootstrapContextProps> = React.createContext({} as any);
