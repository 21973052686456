import React from "react";
import Parallax from "~components/Parallax/Parallax";
import GridContainer from "~components/Grid/GridContainer";
import GridItem from "~components/Grid/GridItem";
import {Translate} from "react-localize-redux";
import blogPostsPageStyle from "~assets/jss/material-kit-pro-react/views/blogPostsPageStyle";
import {WithStyles, withStyles} from "@material-ui/core";
import classNames from "classnames";

export interface LoadingProps {
	image: any;
}

class Loading extends React.Component<LoadingProps & WithStyles, any> {
	render() {
		const {classes} = this.props;

		return (
			<div>
				<Parallax image={this.props.image} filter="dark" small className={classes.parallax}>
					<div className={classes.container}>
						<GridContainer justify="center">
							<GridItem xs={12} sm={12} md={8} className={classes.textCenter}>
								<h2 className={classes.title}>
									<Translate id="Loading..."/>
								</h2>
							</GridItem>
						</GridContainer>
					</div>
				</Parallax>
				<div className={classNames(classes.main, classes.mainRaised)}>
					<div className={classes.container} style={{minHeight: 600}}>
						<h1>
							<Translate id="Please wait"/>
						</h1>
					</div>
				</div>
			</div>
		)
	}
}

export default withStyles(blogPostsPageStyle)(Loading)
