import React from "react";
import {TParams} from "~Shared/Models";
import GridItem from "~components/Grid/GridItem";
import Card from "~components/Card/Card";
import CardBody from "~components/Card/CardBody";
import {getCategoryOrTagFor} from "~Shared/Utils";
import LocaleLink from "~components/Localization/LocaleLink";
import Button from "~components/CustomButtons/Button";
import FormatAlignLeft from "@material-ui/icons/FormatAlignLeft";
import {Translate} from "react-localize-redux";
import {withStyles, WithStyles} from "@material-ui/core/styles";
import sectionPillsStyle from "~assets/jss/material-kit-pro-react/views/blogPostsSections/sectionPillsStyle";
import {RouteComponentProps, withRouter} from "react-router-dom";
import {Drink} from "~CustomApp/Models";
import {drinkImage} from "../../../CustomAppUtils";

interface DisplayDrinkGridItemsProps {
	drinks: firebase.firestore.QueryDocumentSnapshot[];
}

class DisplayDrinkGridItems extends React.Component<DisplayDrinkGridItemsProps & RouteComponentProps<TParams> & WithStyles, any> {
	goTo(slug: string) {
		this.props.history.push(`/${this.props.match.params.locale}/drinks/${slug}`)
	}

	render() {
		const {classes} = this.props;

		return (
			<>
				{this.props.drinks.map((doc, idx) => {
					const drink = doc.data() as Drink;

					return (
						<GridItem xs={12} sm={6} md={6} key={idx}>
							<Card
								raised
								background
								style={{backgroundImage: "url(" + drinkImage(drink) + ")"}}
							>
								<CardBody background>
									<h6 className={classes.category}>
										{getCategoryOrTagFor(drink)}
									</h6>
									<LocaleLink to={`drinks/${doc.id}`}>
										<h3 className={classes.cardTitle}>
											{drink.title}
										</h3>
									</LocaleLink>
									<p className={classes.category}>
										{drink.shortDescription}
									</p>

									<Button round onClick={() => this.goTo(doc.id)} color="warning">
										<FormatAlignLeft className={classes.icons}/> <Translate id="Show"/>
									</Button>
								</CardBody>
							</Card>
						</GridItem>
					)
				})}
			</>
		)
	}
}

export default withRouter(withStyles(sectionPillsStyle)(DisplayDrinkGridItems))
