/* eslint-disable */
import React from "react";
import {withStyles, WithStyles} from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import styles from "~assets/jss/material-kit-pro-react/components/headerLinksStyle";
import {withBootstrap} from "~Shared/withBootstrap";
import {BootstrapContextProps} from "~Shared/BootstrapContext";
import HeaderCategories from "./HeaderCategories";
import HeaderStaticPages from "~components/Header/HeaderStaticPages";
import LocaleLink from "~components/Localization/LocaleLink";
import {AccountBox, ExitToApp, Face, Pageview} from "@material-ui/icons";
import {Translate} from "react-localize-redux";
import CustomAppHeaderLinks from "~CustomApp/CustomAppHeaderLinks";
import CustomDropdown from "~components/CustomDropdown/CustomDropdown";
// @material-ui/icons
// core components

export interface HeaderLinksInterfaceProps {
	dropdownHoverColor: "dark" | "primary" | "info" | "success" | "warning" | "danger" | "rose";
}

class HeaderLinks extends React.Component<HeaderLinksInterfaceProps & WithStyles & BootstrapContextProps, any> {
	easeInOutQuad(t: number, b: number, c: number, d: number) {
		t /= d / 2;
		if (t < 1) return (c / 2) * t * t + b;
		t--;
		return (-c / 2) * (t * (t - 2) - 1) + b;
	}

	scrollGo(element: HTMLElement, to: number, duration: number) {
		let start = element.scrollTop,
			change = to - start,
			currentTime = 0,
			increment = 20;

		const animateScroll = () => {
			currentTime += increment;
			element.scrollTop = this.easeInOutQuad(currentTime, start, change, duration);
			if (currentTime < duration) {
				setTimeout(animateScroll, increment);
			}
		};

		animateScroll();
	}

	smoothScroll(e: React.MouseEvent, target: string) {
		if (window.location.pathname === "/sections") {
			const isMobile = navigator.userAgent.match(
				/(iPad)|(iPhone)|(iPod)|(android)|(webOS)/i
			);

			if (isMobile) {
				// if we are on mobile device the scroll into view will be managed by the browser
			} else {
				e.preventDefault();
				const targetScroll = document.getElementById(target);

				if (targetScroll) {
					this.scrollGo(document.documentElement, targetScroll.offsetTop, 1250);
				}
			}
		}
	}

	userLinks() {
		const {classes} = this.props
		const result = []
		result.push(
			<LocaleLink to={`protected/profile`} className={classes.dropdownLink}>
				<AccountBox/> <Translate id="Profile"/>
			</LocaleLink>
		)
		result.push(
			<LocaleLink to={`logout`} className={classes.dropdownLink}>
				<ExitToApp/> <Translate id="Logout"/>
			</LocaleLink>
		)

		return result
	}

	render() {
		const {classes, dropdownHoverColor} = this.props;

		return (
			<List className={classes.list + " " + classes.mlAuto}>
				<ListItem className={classes.listItem}>
					<LocaleLink to={`posts`} className={classes.navLink}>
						<Pageview/> <Translate id="Posts"/>
					</LocaleLink>
				</ListItem>
				<ListItem className={classes.listItem}>
					<HeaderCategories dropdownHoverColor={dropdownHoverColor}/>
				</ListItem>
				<ListItem className={classes.listItem}>
					<HeaderStaticPages dropdownHoverColor={dropdownHoverColor}/>
				</ListItem>
				<CustomAppHeaderLinks />

				{!!this.props.currentUser &&
				<ListItem className={classes.listItem}>
					<CustomDropdown
						noLiPadding
						navDropdown
						hoverColor={dropdownHoverColor}
						buttonText={this.props.currentUser?.displayName}
						buttonProps={{
							className: classes.navLink,
							color: "transparent"
						}}
						buttonIcon={Face}
						dropdownList={this.userLinks()}
					/>
				</ListItem>
				}
			</List>
		);
	}
}

export default withBootstrap(withStyles(styles)(HeaderLinks))
