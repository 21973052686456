/*eslint-disable*/
import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// react components used to create a google map
// @material-ui/core components
import {makeStyles} from "@material-ui/core/styles";
// @material-ui/icons
// core components
import GridContainer from "~components/Grid/GridContainer";
import GridItem from "~components/Grid/GridItem";
import CustomInput from "~components/CustomInput/CustomInput";
import Button from "~components/CustomButtons/Button";

import contactUsStyle from "~assets/jss/material-kit-pro-react/views/contactUsStyle";
import Parallax from "~components/Parallax/Parallax";
import {Translate} from "react-localize-redux";

const useStyles = makeStyles(contactUsStyle);

export default function ContactUsPage() {
	React.useEffect(() => {
		window.scrollTo(0, 0);
		document.body.scrollTop = 0;
	});
	const classes = useStyles();
	return (
		<div>
			<Parallax image={require("~assets/img/pixabay/hand-3035665_1920.jpg")} filter="dark" className={classes.parallax}>
				<div className={classes.container}>
					<GridContainer justify="center">
						<GridItem md={8} className={classes.textCenter}>
							<h1 className={classes.title}>
								<Translate id="Contact us"/>
							</h1>
							<h4 className={classes.subtitle}>
								<Translate id="Contact form"/>
							</h4>
						</GridItem>
					</GridContainer>
				</div>
			</Parallax>
			<div className={classNames(classes.main, classes.mainRaised)}>
				<div className={classes.contactContent}>
					<div className={classes.container}>
						<h2 className={classes.title}>
							<Translate id="Send us a message" />
						</h2>
						<GridContainer>
							<GridItem md={6} sm={6}>
								<p>
									<Translate id="You can contact us if you have any questions or suggestions. All Feedback Welcome."/>
									<br/>
									<br/>
								</p>
								<form>
									<CustomInput
										labelText={<Translate id="Your Name"/>}
										id="float"
										formControlProps={{
											fullWidth: true
										}}
									/>
									<CustomInput
										labelText={<Translate id="Email address"/>}
										id="float"
										formControlProps={{
											fullWidth: true
										}}
									/>
									<CustomInput
										labelText={<Translate id="Phone"/>}
										id="float"
										formControlProps={{
											fullWidth: true
										}}
									/>
									<CustomInput
										labelText={<Translate id="Your message"/>}
										id="float"
										formControlProps={{
											fullWidth: true
										}}
										inputProps={{
											multiline: true,
											rows: 6
										}}
									/>
									<div className={classes.textCenter}>
										<Button color="primary" round>
											<Translate id="Submit"/>
										</Button>
									</div>
								</form>
							</GridItem>
						</GridContainer>
					</div>
				</div>
			</div>
		</div>
	);
}
