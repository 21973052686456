import * as React from "react";
import LatestDrinks from "../Drinks/LatestDrinks";
import {Category, TParams} from "~Shared/Models";
import {db} from "~components/Firebase";
import {RouteComponentProps} from "react-router";

interface CustomAppCategoryAdditionProps {
	category: Category;
	limit: number;
}

interface CustomAppCategoryAdditionState {
	drinks: firebase.firestore.QueryDocumentSnapshot[];
}

class CustomAppCategoryAddition extends React.Component<CustomAppCategoryAdditionProps & RouteComponentProps<TParams>, CustomAppCategoryAdditionState> {
	public static defaultProps = {
		limit: 25,
	}

	state = {
		drinks: [] as firebase.firestore.QueryDocumentSnapshot[],
	}

	componentDidMount() {
		const locale = this.props.match.params.locale;

		if (!locale) {
			return;
		}

		db
			.collection(`contents/${locale}/drinks`)
			.where('visibility', '==', 'public')
			.where('category', '==', this.props.category.slug)
			.orderBy('createdAt', 'desc')
			.limit(this.props.limit)
			.onSnapshot(snapshot => {
				this.setState({
					drinks: snapshot.docs,
				})
			})
	}

	render() {
		return <LatestDrinks limit={10} category={this.props.category} />
	}
}

export default CustomAppCategoryAddition
