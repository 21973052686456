import React from "react";
import GridContainer from "~components/Grid/GridContainer";
import GridItem from "~components/Grid/GridItem";
import Card from "~components/Card/Card";
import CardHeader from "~components/Card/CardHeader";
import CardBody from "~components/Card/CardBody";
import Info from "~components/Typography/Info";

import sectionSimilarStoriesStyle
	from "~assets/jss/material-kit-pro-react/views/blogPostSections/sectionSimilarStoriesStyle";
import {WithStyles, withStyles} from "@material-ui/core";
import {Translate} from "react-localize-redux";
import {Content, TParams} from "~Shared/Models";
import LocaleLink from "~components/Localization/LocaleLink";
import {db, queryForDocuments} from "~components/Firebase";
import {RouteComponentProps, withRouter} from "react-router-dom";
import {ContentListPageProps} from "~views/Contents/ContentListPage";
import {getCategoryOrTagFor, handleImageList} from "~Shared/Utils";

export interface LatestContentState {
	contents: Content[];
}

class LatestContent extends React.Component<ContentListPageProps & WithStyles & RouteComponentProps<TParams>, LatestContentState> {
	state = {
		contents: [],
	}

	componentDidMount() {
		const locale = this.props.match.params.locale;

		if (!locale) {
			console.warn('No locale');
			return;
		}

		let collectionPath = `contents/${locale}/${this.props.collectionPath}`;
		let query = db
			.collection(collectionPath)
			.where('visibility', '==', 'public')
			.orderBy('createdAt', 'desc')
			.limit(this.props.limit)

		queryForDocuments(query, result => {
			console.log('+++++++++++++++++++++++++++++++++++++', result.documents)
			this.setState({
				contents: result.documents as Content[],
			})
		}, err => {
			console.error('ERROR', err)
		})
	}

	render() {
		const {classes} = this.props

		return (
			<div className={classes.section}>
				<div className={classes.container}>
					<GridContainer>
						<GridItem md={12}>
							<h2 className={classes.title + " " + classes.textCenter}>
								<Translate id="Latests Posts"/>
							</h2>
							<br/>
							<GridContainer>
								{this.state.contents.map((content, idx) => {
									handleImageList(this, content, idx)

									return (
										<GridItem xs={12} sm={4} md={4} key={idx}>
											<Card blog>
												<CardHeader image>
													<LocaleLink to={`posts/${content.slug}`}>
														<img src={content.imageUrl} alt={content.title}/>
													</LocaleLink>
													<div
														className={classes.coloredShadow}
														style={{
															backgroundImage: "url(" + content.image + ")",
															opacity: "1"
														}}
													/>
												</CardHeader>
												<CardBody>
													<Info>
														<h6>{getCategoryOrTagFor(content)}</h6>
													</Info>
													<h4 className={classes.cardTitle}>
														<LocaleLink to={`posts/${content.slug}`}>
															{content.title}
														</LocaleLink>
													</h4>
													<p className={classes.description}>
														{content.body_short}
														<LocaleLink to={`posts/${content.slug}`}>
															<Translate id="Read More"/>
														</LocaleLink>
													</p>
												</CardBody>
											</Card>
										</GridItem>
									);
								})}
							</GridContainer>
						</GridItem>
					</GridContainer>
				</div>
			</div>
		)
	}
}

export default withRouter(withStyles(sectionSimilarStoriesStyle)(LatestContent))
