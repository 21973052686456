/*eslint-disable*/
import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import {makeStyles} from "@material-ui/core/styles";
// @material-ui/icons
// core components
import GridContainer from "~components/Grid/GridContainer";
import GridItem from "~components/Grid/GridItem";
import Parallax from "~components/Parallax/Parallax";
// sections for this page
import SectionDescription from "~views/AboutUsPage/Sections/SectionDescription";
import SectionTeam from "~views/AboutUsPage/Sections/SectionTeam";
import SectionServices from "~views/AboutUsPage/Sections/SectionServices";
import SectionOffice from "~views/AboutUsPage/Sections/SectionOffice";
import SectionContact from "~views/AboutUsPage/Sections/SectionContact";

import aboutUsStyle from "~assets/jss/material-kit-pro-react/views/aboutUsStyle";

const useStyles = makeStyles(aboutUsStyle);

export default function AboutUsPage() {
	React.useEffect(() => {
		window.scrollTo(0, 0);
		document.body.scrollTop = 0;
	});
	const classes = useStyles();
	return (
		<div>
			<Parallax image={require("~assets/img/bg9.jpg")} filter="dark" className={classes.parallax}>
				<div className={classes.container}>
					<GridContainer justify="center">
						<GridItem
							md={8}
							sm={8}
							className={classNames(
								classes.mlAuto,
								classes.mrAuto,
								classes.textCenter
							)}
						>
							<h1 className={classes.title}>About Us</h1>
							<h4>
								Meet the amazing team behind this project and find out more
								about how we work.
							</h4>
						</GridItem>
					</GridContainer>
				</div>
			</Parallax>
			<div className={classNames(classes.main, classes.mainRaised)}>
				<div className={classes.container}>
					<SectionDescription/>
					<SectionTeam/>
					<SectionServices/>
					<SectionOffice/>
					<SectionContact/>
				</div>
			</div>
		</div>
	);
}
