import { main, mainRaised } from "~assets/jss/material-kit-pro-react";

const sectionsPageStyle = {
  main: {
    ...main,
		...mainRaised
  }
};

export default sectionsPageStyle;
