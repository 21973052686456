import * as React from "react";
import {LocalizeContextProps, Translate, withLocalize} from "react-localize-redux";
import {Drink} from "~CustomApp/Models";
import {withStyles, WithStyles} from "@material-ui/core/styles";
import {RouteComponentProps, withRouter} from "react-router-dom";
import {TParams} from "~Shared/Models";
import {retrieveDocumentFromFirestore} from "~components/Firebase";
import Loading from "~components/Loading/Loading";
import blogPostsPageStyle from "~assets/jss/material-kit-pro-react/views/blogPostsPageStyle";
import MetaTags from "~components/Utils/MetaTags";
import Parallax from "~components/Parallax/Parallax";
import GridContainer from "~components/Grid/GridContainer";
import GridItem from "~components/Grid/GridItem";
import classNames from "classnames";
import DrinkInfo from "~CustomApp/Views/Drinks/Components/DrinkInfo";
import {drinkImage} from "~CustomApp/CustomAppUtils";
import NotFound from "~components/ErrorPage/NotFound";
import SectionText from "~components/Content/SectionText";
import DisplayComments from "~components/Comments/DisplayComments";

interface ShowDrinkPageState {
	drink: Drink;
	documentFound: boolean;
	isLoading: boolean;
}

class ShowDrinkPage extends React.Component<LocalizeContextProps & WithStyles & RouteComponentProps<TParams>, ShowDrinkPageState> {
	state = {
		drink: {} as Drink,
		documentFound: true,
		isLoading: true,
	}

	componentDidMount() {
		window.scrollTo(0, 0);
		document.body.scrollTop = 0;

		const docId = this.props.match.params.id;
		this.loadData(docId);
	}

	loadData(docId: string) {
		const locale = this.props.match.params.locale;
		let collectionPath = `contents/${locale}/drinks`;
		retrieveDocumentFromFirestore(collectionPath, docId, doc => {
			if (!doc.exists) {
				console.error(`Document does not exist! ${collectionPath}/${docId}`);
				this.setState({documentFound: false, isLoading: false})
				return;
			}

			let drink = doc.data() as Drink
			drink.id = doc.id
			console.log('DisplayContentPage', drink)

			this.setState({drink: drink as Drink, documentFound: true, isLoading: false})
		}, err => {
			this.setState({documentFound: false, isLoading: false})
		})
	}

	componentDidUpdate(prevProps: Readonly<LocalizeContextProps & WithStyles & RouteComponentProps<TParams>>, prevState: Readonly<ShowDrinkPageState>, snapshot?: any) {
		const docId = this.props.match.params.id;
		if (prevProps.match.params.id !== docId) {
			this.loadData(docId);
		}
	}

	render() {
		const {classes} = this.props;
		const {drink} = this.state;
		const ref = React.createRef<HTMLDivElement>();

		if (this.state.isLoading) {
			return <Loading/>
		}

		if (!this.state.documentFound) {
			return <NotFound/>
		}

		return (
			<div>
				<MetaTags title={drink.title}/>
				<Parallax image={drinkImage(drink)} filter="dark" className={classes.parallax}>
					<div className={classes.container}>
						<GridContainer justify="center">
							<GridItem md={8} className={classes.textCenter}>
								<h1 className={classes.title}>
									{drink.title}
								</h1>
							</GridItem>
						</GridContainer>
					</div>
				</Parallax>
				<div className={classNames(classes.main, classes.mainRaised)}>
					<div className={classes.container} ref={ref}>
						{drink.brand &&
						<p>
							<Translate id="Brand"/>: {drink.brand}
						</p>
						}
						{drink.country &&
						<p>
							<Translate id="Country"/>: {drink.country}
						</p>
						}
						{drink.price &&
						<p>
							<Translate id="Price"/>: {drink.price}
						</p>
						}
						{drink.affiliateUrl &&
						<a href={drink.affiliateUrl} target="_blank">
							<Translate id="Buy"/>
						</a>
						}

						<SectionText body={drink.description}/>
						<DrinkInfo drink={drink}/>
						<DisplayComments comments={[]}/>
					</div>
				</div>
			</div>
		);
	}
}

export default withLocalize(withRouter(withStyles(blogPostsPageStyle)(ShowDrinkPage)))
