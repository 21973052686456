import * as React from "react";
import LatestDrinks from "../Drinks/LatestDrinks";

class CustomAppCategoriesAddition extends React.Component<any, any> {
	render() {
		return <LatestDrinks limit={10} />
	}
}

export default CustomAppCategoriesAddition
