import React from "react";
import {WithStyles, withStyles} from "@material-ui/core/styles";
import GridContainer from "~components/Grid/GridContainer";
import Button from "~components/CustomButtons/Button";

import sectionPillsStyle from "~assets/jss/material-kit-pro-react/views/blogPostsSections/sectionPillsStyle";
import {TParams} from "~Shared/Models";
import {Translate} from "react-localize-redux";
import {withRouter} from "react-router-dom";
import {RouteComponentProps} from "react-router";
import DisplayDrinkGridItems from "~CustomApp/Views/Drinks/Components/DisplayDrinkGridItems";

interface GroupSectionPillsProps {
	loadNext: () => void;
	loadPrevious: () => void;
	drinks: firebase.firestore.QueryDocumentSnapshot[];
}

class DrinkSectionPills extends React.Component<GroupSectionPillsProps & WithStyles & RouteComponentProps<TParams>, any> {
	render() {
		const {classes, drinks} = this.props;

		return (
			<div className={classes.section}>
				<GridContainer>
					<DisplayDrinkGridItems drinks={drinks}/>
				</GridContainer>
				<GridContainer>
					<Button onClick={this.props.loadPrevious} className={classes.buttons}>
						<Translate id="Previous"/>
					</Button>
					<Button onClick={this.props.loadNext} className={classes.buttons}>
						<Translate id="Next"/>
					</Button>
				</GridContainer>
			</div>
		);
	}
}

export default withRouter(withStyles(sectionPillsStyle)(DrinkSectionPills))
