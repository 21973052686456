import React from "react";
import {Redirect, Route, RouteComponentProps, Switch, withRouter} from "react-router-dom";
import Home from "../views/Public/Home";
import NotFound from "~components/ErrorPage/NotFound";
import LoginPage from "~views/LoginPage/LoginPage";
import LandingPage from "~views/LandingPage/LandingPage";
import ContactUsPage from "~views/ContactUsPage/ContactUsPage";
import AboutUsPage from "~views/AboutUsPage/AboutUsPage";
import SignUpPage from "~views/SignupPage/SignupPage";
import SectionsPage from "~views/SectionsPage/SectionsPage";
import RedirectToLocale from "~Shared/RedirectToLocale";
import Header from "~components/Header/Header";
import Footer from "~components/Footer/Footer";
import CategoryPage from "~views/Categories/CategoryPage";
import CustomAppRoutes from "~CustomApp/CustomAppRoutes";
import CategoriesPage from "~views/Categories/CategoriesPage";
import ContentListPage from "~views/Contents/ContentListPage";
import DisplayContentPage from "~views/Contents/DisplayContentPage";
import CookieBanner from "~components/Utils/CookieBanner";
import ProtectedHome from "~views/Protected/ProtectedHome";
import {BootstrapContextProps} from "~Shared/BootstrapContext";
import {withBootstrap} from "~Shared/withBootstrap";
import {TParams} from "~Shared/Models";
import ProfilePage from "~views/Protected/ProfilePage/ProfilePage";
import 'react-tagsinput/react-tagsinput.css'
import PrivateRoute from "~components/Routes/PrivateRoute";
import {auth} from "~components/Firebase";

class App extends React.Component<BootstrapContextProps & RouteComponentProps<TParams>, any> {
	render() {
		const {location} = this.props;

		return (
			<>
				<div id="app">
					<Header
						fixed
						color="transparent"
						changeColorOnScroll={{
							height: 300,
							color: "white"
						}}
					/>
					{this.props.bootstrapFinished &&
					<Switch>
						<Redirect from="/:url*(/+)" to={location.pathname.slice(0, -1)}/>

						<Route path="/:locale/protected/profile" exact={true} render={() => {
							return <PrivateRoute component={<ProfilePage/>}/>
						}}/>
						<Route path="/:locale/protected" exact={true} render={() => {
							return <PrivateRoute component={<ProtectedHome/>}/>
						}}/>

						<Route path="/:locale/pages/:id" exact={true}>
							<DisplayContentPage collectionPath="pages"/>
						</Route>
						<Route path="/:locale/pages" exact={true}>
							<ContentListPage title="Pages" collectionPath="pages"/>
						</Route>
						<Route path="/:locale/posts/:id" exact={true}>
							<DisplayContentPage collectionPath="posts"/>
						</Route>
						<Route path="/:locale/posts" exact={true}>
							<ContentListPage title="Posts" collectionPath="posts"/>
						</Route>
						<Route path="/:locale/categories/:id" exact={true} component={CategoryPage}/>
						<Route path="/:locale/categories" exact={true} component={CategoriesPage}/>
						<Route path="/:locale/about-us" component={AboutUsPage} exact={true}/>
						<Route path="/:locale/contact-us" component={ContactUsPage} exact={true}/>
						<Route path="/:locale/login" component={LoginPage} exact={true}/>
						<Route path="/:locale/signup" component={SignUpPage} exact={true}/>
						<Route path="/:locale/logout" exact={true} render={async () => {
							await auth.signOut()
							return <Redirect to={`/${this.props.match.params.locale}`}/>
						}}/>
						<Route path="/landing-page" component={LandingPage} exact={true}/>
						<Route path="/sections" component={SectionsPage} exact={true}/>
						<Route path="/:locale" component={Home} exact={true}/>
						<Route path="/" component={RedirectToLocale} exact={true}/>

						<CustomAppRoutes/>

						<Route component={NotFound}/>
					</Switch>
					}
					<Footer/>
				</div>
				<CookieBanner/>
			</>
		)
	}
}

export default withBootstrap(withRouter(App))
