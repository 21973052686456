import React from "react";
import {LocalizeContextProps, withLocalize} from "react-localize-redux";
import {Helmet} from "react-helmet";
import {allowedRobotHost, metaData} from "~config";

export interface MetaTagsProps {
	title: string;
}

class MetaTags extends React.Component<LocalizeContextProps & MetaTagsProps, any> {
	render() {
		const title = `${this.props.title} - ${metaData.title}`
		const robotsIndex =  (window.location.host === allowedRobotHost) ? 'index,follow' : 'noindex,nofollow'

		return (
			<Helmet>
				<title>{title}</title>
				<meta name="robots" content={robotsIndex} />
			</Helmet>
		)
	}
}

export default withLocalize(MetaTags)
