import React from "react";
import {Link} from "react-router-dom";
import {BootstrapContextProps} from "~Shared/BootstrapContext";
import {withBootstrap} from "~Shared/withBootstrap";

export interface LocaleLinkProps {
	to: string;
}

class LocaleLink extends React.Component<BootstrapContextProps & LocaleLinkProps, any> {
	render() {
		return <Link to={`/${this.props.currentLocale}/${this.props.to}`} className={this.props.className}>
			{this.props.children}
		</Link>
	}
}

export default withBootstrap(LocaleLink)
