import * as React from "react";
import {
	BootstrapContext,
	BootstrapContextFooter,
	BootstrapContextHeader,
	BootstrapContextHome,
	BootstrapContextProps
} from "./BootstrapContext";
import {LocalizeContextProps, LocalizeState, withLocalize} from "react-localize-redux";
import update from "immutability-helper";
import firebase from "firebase/app";
import {withRouter} from "react-router-dom";
import {RouteComponentProps} from "react-router";
import {retrieveDocumentFromFirestore} from "~components/Firebase";
import * as translationsDe from "~Translations/de";
import * as translationsEn from "~Translations/en";
import * as customAppTranslationsDe from "~CustomApp/Translations/de";
import * as customAppTranslationsEn from "~CustomApp/Translations/en";
import LocalizeHelper from "~Shared/LocalizeHelper";
import {TParams} from "~Shared/Models";
import {availableLanguages} from "~config";

export interface BootstrapProviderState {
	bootstrapLoaded: boolean;
	userLoaded: boolean;
	locale: string;
	bootstrap: BootstrapContextProps;
}

interface BootstrapProviderProps {
	app: string;
}

class BootstrapProvider extends React.Component<LocalizeContextProps & LocalizeState & BootstrapProviderProps & RouteComponentProps<TParams>, BootstrapProviderState> {
	private unregisterAuthObserver: firebase.Unsubscribe | null = null;

	state = {
		user: {},
		locale: '',
		bootstrapLoaded: false,
		userLoaded: false,
		bootstrap: {
			bootstrapFinished: false,
			categories: [],
			changeLocale: this.changeLocale,
			currentLocale: 'en',
			currentUser: null,
			footer: {
				links: {},
			} as BootstrapContextFooter,
			header: {
				brand: '',
				staticPages: [],
			} as BootstrapContextHeader,
			home: {
				displayLatestPosts: false,
				body: '',
			} as BootstrapContextHome
		} as BootstrapContextProps,
	};

	constructor(props: any) {
		super(props);

		this.props.initialize(LocalizeHelper.localeConfig());
		this.props.addTranslationForLanguage(translationsDe.translations, 'de');
		this.props.addTranslationForLanguage(customAppTranslationsDe.translations, 'de');
		this.props.addTranslationForLanguage(translationsEn.translations, 'en');
		this.props.addTranslationForLanguage(customAppTranslationsEn.translations, 'en');

		console.warn(this.props.activeLanguage, this.props.languages);
		this.changeLocale = this.changeLocale.bind(this);
	}

	changeLocale(locale: string) {
		console.log(`Change locale to ${locale}`);
		this.props.history.push(locale);
	}

	isValidLocale(locale: string) {
		for (const language of availableLanguages) {
			if (language.code === locale) {
				return true
			}
		}

		return false
	}

	loadBootstrap(locale: string): void {
		if (!locale) {
			console.error('No locale provided in loadBootstrap');

			if (this.props.location.pathname === '/') {
				this.updateBootstrapState(true, this.state.userLoaded)
			}

			return;
		}

		if (this.props.location.pathname !== '/' && !this.isValidLocale(locale)) {
			this.props.history.push('/')
			return
		}

		console.log(`Loading Bootstrap data for locale ${locale}`);
		this.props.setActiveLanguage(locale);
		retrieveDocumentFromFirestore(`/contents/${locale}/bootstrap`, 'public', doc => {
			const bootstrapData = doc.data()
			console.log('bootstrap data', bootstrapData);

			if (bootstrapData === undefined) {
				console.error('Error bootstrap data is undefined')
				return;
			}

			const newData = update(this.state.bootstrap, {
				categories: {$set: bootstrapData.categories},
				footer: {$set: bootstrapData.footer},
				header: {$set: bootstrapData.header},
				home: {$set: bootstrapData.home},
				currentLocale: {$set: bootstrapData.locale},
			})

			this.setState({bootstrap: newData, bootstrapLoaded: true});
			this.updateBootstrapState(true, this.state.userLoaded)
		}, err => {
			console.error('Error reading bootstrap data', err)
		})
	}

	componentDidMount(): void {
		this.unregisterAuthObserver = firebase.auth().onAuthStateChanged((user) => {
			this.updateBootstrapState(this.state.bootstrapLoaded, true)

				if (user === null) {
					return;
				}

				console.log('userData', user);
				const newData = update(this.state.bootstrap, {
					currentUser: {$set: user},
				})

				this.setState({bootstrap: newData})
			}
		);

		const locale = this.props.match.params.locale;
		this.loadBootstrap(locale)
	}

	localeFromLocation(): string {
		const parts = this.props.location.pathname.split('/')
		return parts[1]
	}

	updateBootstrapState(bootstrapLoaded: boolean, userLoaded: boolean) {
		const newData = update(this.state.bootstrap, {
			bootstrapFinished: {$set: bootstrapLoaded && userLoaded},
		})
		console.log('Is Bootstrap finished', newData.bootstrapFinished)
		this.setState({bootstrapLoaded: bootstrapLoaded, userLoaded: userLoaded, bootstrap: newData})
	}

	componentDidUpdate(prevProps: Readonly<LocalizeContextProps & BootstrapProviderProps & RouteComponentProps>, prevState: Readonly<BootstrapProviderState>, snapshot?: any) {
		const locale = this.localeFromLocation();

		if (locale !== undefined && locale !== this.state.locale) {
			console.log(`Locale changed from ${this.state.locale} to ${locale}`);
			this.setState({locale: locale});
			if (locale) {
				this.loadBootstrap(locale)
			}
		}
	}

	componentWillUnmount() {
		if (this.unregisterAuthObserver) {
			this.unregisterAuthObserver();
		}
	}

	render() {
		const contextProps = this.state;

		return (
			<BootstrapContext.Provider value={contextProps.bootstrap}>
				{this.props.children}
			</BootstrapContext.Provider>
		);
	}
}

export default withRouter(withLocalize(BootstrapProvider))
