export const translations = {
	'About us': 'About us',
	'Add': 'Add',
	'Accept': 'Accept',
	'Categories': 'Categories',
	'Category': 'Category',
	'Contact us': 'Contact us',
	'Contact form': 'Contact form',
	'Creator': 'Creator',
	'Data Protection': 'Data Protection',
	'Description': 'Description',
	'Email address': 'Email address',
	'German': 'German',
	'Group Info': 'Group Info',
	'Imprint': 'Imprint',
	'Language': 'Language',
	'Latests Posts': 'Latests Posts',
	'Loading...': 'Loading...',
	'Logout': 'Logout',
	'Member': 'Member',
	'Members': 'Members',
	'Messages': 'Messages',
	'Next': 'Next',
	'Pages': 'Pages',
	'Phone': 'Phone',
	'Please wait': 'Please wait',
	'Post your comment': 'Post your comment',
	'Posts': 'Posts',
	'Previous': 'Previous',
	'Profile': 'Profile',
	'Read Article': 'Read Article',
	'Read More': 'Read More',
	'Save': 'Save',
	'Send us a message': 'Send us a message',
	'Settings': 'Settings',
	'Show': 'Show',
	'Subtitle': 'Subtitle',
	'Success Journal': 'Success Journal',
	'Submit': 'Submit',
	'Tags': 'Tags',
	'Title': 'Title',
	'Visibility': 'Visibility',
	'We use cookies to enhance your experience.': 'We use cookies to enhance your experience.',
	'Wishes and goals': 'Wishes and goals',
	'You can contact us if you have any questions or suggestions. All Feedback Welcome.': 'You can contact us if you have any questions or suggestions. All Feedback Welcome.',
	'Your message': 'Your message',
	'Your Name': 'Your Name',
};
