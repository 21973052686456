import {withBootstrap} from "~Shared/withBootstrap";
import {BootstrapContextProps} from "~Shared/BootstrapContext";
import {Link} from "react-router-dom";
import CustomDropdown from "~components/CustomDropdown/CustomDropdown";
import React from "react";
import {withStyles, WithStyles} from "@material-ui/core/styles";

import styles from "~assets/jss/material-kit-pro-react/components/headerLinksStyle";
import {HeaderLinksInterfaceProps} from "~components/Header/HeaderLinks";
import {Translate} from "react-localize-redux";
import Icon from '@material-ui/core/Icon';
import {Pages} from "@material-ui/icons";
import LocaleLink from "~components/Localization/LocaleLink";

class HeaderStaticPages extends React.Component<BootstrapContextProps & WithStyles & HeaderLinksInterfaceProps, any> {
	render() {
		const {classes, dropdownHoverColor} = this.props;

		const staticPagesLinks = []
		for (const staticPage of this.props.header.staticPages) {
			staticPagesLinks.push(
				<LocaleLink to={`pages/${staticPage.slug}`} className={classes.dropdownLink}>
					<Icon className={classes.dropdownIcons} key={staticPage.icon}/> {staticPage.title}
				</LocaleLink>
			)
		}

		return (
			<CustomDropdown
				noLiPadding
				navDropdown
				hoverColor={dropdownHoverColor}
				buttonText={<Translate id="Pages"/>}
				buttonProps={{
					className: classes.navLink,
					color: "transparent"
				}}
				buttonIcon={Pages}
				dropdownList={staticPagesLinks}
			/>
		)
	}
}

export default withBootstrap(withStyles(styles)(HeaderStaticPages))
