/*eslint-disable*/
import React from "react";
// @material-ui/core components
import {withStyles, WithStyles} from "@material-ui/core/styles";
// @material-ui/icons
// core components
import GridContainer from "~components/Grid/GridContainer";
import GridItem from "~components/Grid/GridItem";
import Card from "~components/Card/Card";

import loginPageStyle from "~assets/jss/material-kit-pro-react/views/loginPageStyle";

import image from "~assets/img/bg7.jpg";
import {StyledFirebaseAuth} from "react-firebaseui";
import {firebaseUiConfig} from "~components/Firebase";
import firebase from "firebase/app";
import {withBootstrap} from "~Shared/withBootstrap";
import {BootstrapContextProps} from "~Shared/BootstrapContext";
import { withRouter, RouteComponentProps } from "react-router-dom";
import {TParams} from "~Shared/Models";

class LoginPage extends React.Component<BootstrapContextProps & RouteComponentProps<TParams> & WithStyles, any> {
	componentDidMount() {
		window.scrollTo(0, 0);
		document.body.scrollTop = 0;

		if (!!this.props.currentUser) {
			this.redirectToProtectedHome()
		}
	}

	componentDidUpdate(prevProps: Readonly<BootstrapContextProps & RouteComponentProps & WithStyles>, prevState: Readonly<any>, snapshot?: any) {
		if (!!this.props.currentUser) {
			this.redirectToProtectedHome()
		}
	}

	redirectToProtectedHome() {
		console.log('Redirecting', this.props.currentUser, `/${this.props.match.params.locale}/protected`);
		this.props.history.push(`/${this.props.match.params.locale}/protected`);
	}

	render() {
		const {classes} = this.props;
		return (
			<div
				className={classes.pageHeader}
				style={{
					backgroundImage: "url(" + image + ")",
					backgroundSize: "cover",
					backgroundPosition: "top center"
				}}
			>
				<div className={classes.container}>
					<GridContainer justify="center">
						<GridItem xs={12} sm={12} md={4}>
							<Card>
								<StyledFirebaseAuth uiConfig={firebaseUiConfig} firebaseAuth={firebase.auth()}/>
							</Card>
						</GridItem>
					</GridContainer>
				</div>
			</div>
		);
	}
}

export default withRouter(withBootstrap(withStyles(loginPageStyle)(LoginPage)))
