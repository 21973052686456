class StorageHelper {
	public static getItem(key: string, defaultValue: any = null) {
		return localStorage.getItem(key) || defaultValue
	}

	public static setItem(key: string, value: any) {
		localStorage.setItem(key, value);
	}
}

export default StorageHelper
