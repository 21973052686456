import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import {withStyles, WithStyles} from "@material-ui/core/styles";
// @material-ui/icons
import GridContainer from "~components/Grid/GridContainer";
import GridItem from "~components/Grid/GridItem";
import sectionTextStyle from "~assets/jss/material-kit-pro-react/views/blogPostSections/sectionTextStyle";
import InnerHTML from 'dangerously-set-html-content'

export interface SectionTextProps {
	body: string;
}

class SectionText extends React.Component<SectionTextProps & WithStyles, any> {
	render() {
		const {classes} = this.props;
		const imgClasses = classNames(
			classes.imgRaised,
			classes.imgRounded,
			classes.imgFluid
		);
		return (
			<div className={classes.section}>
				<GridContainer justify="center">
					<GridItem xs={12} sm={8} md={8}>
						<InnerHTML html={this.props.body} />
					</GridItem>
				</GridContainer>
			</div>
		);
	}
}

export default withStyles(sectionTextStyle)(SectionText)
