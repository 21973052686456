import React from "react";
import {withStyles, WithStyles} from "@material-ui/core/styles";
import Parallax from "~components/Parallax/Parallax";
import GridContainer from "~components/Grid/GridContainer";
import GridItem from "~components/Grid/GridItem";

import blogPostPageStyle from "~assets/jss/material-kit-pro-react/views/blogPostPageStyle";
import {Category, TParams} from "~Shared/Models";
import MetaTags from "~components/Utils/MetaTags";
import classNames from "classnames";
import {db} from "~components/Firebase";
import {RouteComponentProps, withRouter} from "react-router-dom";
import CustomAppCategoryAddition from "~CustomApp/Components/Categories/CustomAppCategoryAddition";

interface CategoryPageState {
	category: Category;
}

class CategoryPage extends React.Component<WithStyles & RouteComponentProps<TParams>, CategoryPageState> {
	state = {
		category: {} as Category,
	}

	componentDidMount() {
		window.scrollTo(0, 0);
		document.body.scrollTop = 0;

		this.loadCategory()
	}

	componentDidUpdate(prevProps: Readonly<WithStyles & RouteComponentProps<TParams>>, prevState: Readonly<CategoryPageState>, snapshot?: any) {
		if (this.props.match.params.id !== prevProps.match.params.id) {
			this.loadCategory()
		}
	}

	loadCategory() {
		const locale = this.props.match.params.locale;
		db
			.doc(`contents/${locale}/categories/${this.props.match.params.id}`)
			.get()
			.then(doc => {
				this.setState({category: doc.data() as Category})
			})
	}

	render() {
		const {classes} = this.props
		const {category} = this.state

		return (
			<div>
				<MetaTags title={category.title}/>
				<Parallax image={require("~assets/img/bg5.jpg")} filter="dark" className={classes.parallax}>
					<div className={classes.container}>
						<GridContainer justify="center">
							<GridItem md={8} className={classes.textCenter}>
								<h1 className={classes.title}>
									{category.title}
								</h1>
								<h4 className={classes.subtitle}>
									{category.subtitle}
								</h4>
							</GridItem>
						</GridContainer>
					</div>
				</Parallax>
				<div className={classNames(classes.main, classes.mainRaised)}>
					<div className={classes.container}>
						<CustomAppCategoryAddition category={category}/>
					</div>
				</div>
			</div>
		);
	}
}

export default withRouter(withStyles(blogPostPageStyle)(CategoryPage))
