import React from "react";
import {categoryAssets, titleImage} from "~config";
import {Content} from "~Shared/Models";
import {storage} from "~components/Firebase";
import firebase from 'firebase/app';
import update from "immutability-helper";

export const scrollTo = (ref: React.RefObject<HTMLDivElement>) => {
	if (!ref.current) {
		return;
	}

	ref.current.scrollIntoView({
		behavior: 'smooth',
		block: 'start',
	});
}

export const getCategoryOrTagFor = (content: any) => {
	if (content.category) {
		return content.category
	}

	if (content.tags) {
		return content.tags[0]
	}

	return 'General'
}

export const imageForCategoryOrTag = (title: string): firebase.storage.Reference => {
	if (categoryAssets[title]) {
		return storage.ref(categoryAssets[title])
	}

	return storage.ref(categoryAssets['Default'])
}

export const contentImage = (content: Content): firebase.storage.Reference => {
	if (content.image) {
		return storage.ref(content.image);
	}

	return imageForCategoryOrTag(getCategoryOrTagFor(content))
}

export const handleImage = (that: any, content: Content) => {
	if (!content.imageUrl) {
		console.log(content.imageUrl)
		contentImage(content)
			.getDownloadURL()
			.then(url => {
				const newValue = update(that.state.content, {
					imageUrl: {$set: url},
				})

				that.setState({content: newValue});
			})
			.catch(err => {
				console.error('Error retrieving url for content', err)
				const newValue = update(that.state.content, {
					imageUrl: {$set: titleImage},
				})

				that.setState({content: newValue});
			})
	}
}

export const handleImageList = (that: any, content: Content, idx: number) => {
	if (!content.imageUrl) {
		contentImage(content)
			.getDownloadURL()
			.then(url => {
				const newValue = update(that.state.contents, {
					[idx]: {
						$merge: {
							imageUrl: url,
						}
					},
				})

				that.setState({contents: newValue});
			})
			.catch(err => {
				console.error('Error retrieving url for content', err, that)
				const newValue = update(that.state.contents, {
					[idx]: {
						$merge: {
							imageUrl: titleImage,
						}
					},
				})

				that.setState({contents: newValue});
			})
	}
}

export const slugify = (text: string) => {
	return text
		.toString()                     // Cast to string
		.toLowerCase()                  // Convert the string to lowercase letters
		.normalize('NFD')       // The normalize() method returns the Unicode Normalization Form of a given string.
		.trim()                         // Remove whitespace from both sides of a string
		.replace(/\s+/g, '-')           // Replace spaces with -
		.replace(/[^\w\-]+/g, '')       // Remove all non-word chars
		.replace(/\-\-+/g, '-');        // Replace multiple - with single -
}
