/* eslint-disable */
import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import {WithStyles, withStyles} from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";

import footerStyle from "~assets/jss/material-kit-pro-react/components/footerStyle";
import {withBootstrap} from "~Shared/withBootstrap";
import {BootstrapContextProps} from "~Shared/BootstrapContext";
import {Translate} from "react-localize-redux";
import {Link} from "react-router-dom";

interface FooterProps {
	big: boolean;
	className: string;
	theme: "dark" | "white" | "transparent";
}

class Footer extends React.Component<BootstrapContextProps & FooterProps & WithStyles, any> {
	render() {
		const {children, theme, big, className, classes} = this.props;
		const themeType = !(theme === "transparent" || theme == undefined);
		const footerClasses = classNames({
			[classes.footer]: true,
			[classes[theme]]: themeType,
			[classes.big]: big || children !== undefined,
			[className]: className !== undefined
		});
		const footerLinks = this.props.footer

		return (
			<footer className={footerClasses}>
				<div className={classes.container}>
					{children !== undefined ? (
						<div>
							<div className={classes.content}>{children}</div>
							<hr/>
						</div>
					) : (
						" "
					)}
					<div>
						<div className={classes.left}>
							<List className={classes.list}>
								{Object.keys(footerLinks.links).map((title, idx) => {
									const url = footerLinks.links[title]
									return (
										<ListItem className={classes.inlineBlock} key={idx}>
											<a href={url} target="_blank" className={classes.block}>{title}</a>
										</ListItem>
									)
								})}
								<ListItem className={classes.inlineBlock}>
									<Link to={`/${this.props.currentLocale}/about-us`}>
										<Translate id="About us" />
									</Link>
								</ListItem>
								<ListItem className={classes.inlineBlock}>
									<a
										href="https://www.odania-it.com/data-protection"
										target="_blank"
										className={classes.block}
									>
										<Translate id="Data Protection"/>
									</a>
								</ListItem>
								<ListItem className={classes.inlineBlock}>
									<a
										href="https://www.odania-it.com/imprint"
										target="_blank"
										className={classes.block}
									>
										<Translate id="Imprint"/>
									</a>
								</ListItem>
								<ListItem className={classes.inlineBlock}>
									<Link to={`/${this.props.currentLocale}/contact-us`}>
										<Translate id="Contact us" />
									</Link>
								</ListItem>
							</List>
						</div>
						<div className={classes.right}>
							&copy; {new Date().getFullYear()} by&nbsp;
							<a href="https://www.odania-it.com" target="_blank">
								Mike Petersen Odania IT
							</a>.
						</div>
					</div>
					<div className={classes.clearFix}/>
				</div>
			</footer>
		);
	}
}

export default withBootstrap(withStyles(footerStyle)(Footer))
