import React from "react";
import {WithStyles, withStyles} from "@material-ui/core/styles";
import GridContainer from "~components/Grid/GridContainer";
import GridItem from "~components/Grid/GridItem";
import Badge from "~components/Badge/Badge";

import sectionBlogInfoStyle from "~assets/jss/material-kit-pro-react/views/blogPostSections/sectionBlogInfoStyle";
import {Translate} from "react-localize-redux";
import LocaleLink from "~components/Localization/LocaleLink";
import UserProfile from "~components/User/UserProfile";
import {Drink} from "~CustomApp/Models";

interface InterfaceProps {
	drink: Drink;
}

class DrinkInfo extends React.Component<InterfaceProps & WithStyles, any> {
	render() {
		const {classes, drink} = this.props;

		return (
			<div className={classes.section}>
				<GridContainer justify="center">
					<GridItem xs={12} sm={10} md={8}>
						<GridContainer>
							{drink.tags &&
							<GridItem xs={12} sm={6} md={6}>
								<div className={classes.blogTags}>
									<Translate id="Tags"/>:
									{drink.tags.map((tag, idx) => {
										return <LocaleLink to={`tags/${tag}`} key={idx}>
											<Badge color="primary">{tag}</Badge>
										</LocaleLink>
									})}
								</div>
							</GridItem>
							}
							{drink.category &&
							<GridItem xs={12} sm={6} md={6}>
								<Translate id="Category"/>:
								<LocaleLink to={`categories/${drink.category}`}>
									<Badge color="info">{drink.category}</Badge>
								</LocaleLink>
							</GridItem>
							}
						</GridContainer>
						<hr/>
						<UserProfile/>
					</GridItem>
				</GridContainer>
			</div>
		);
	}
}

export default withStyles(sectionBlogInfoStyle)(DrinkInfo)
